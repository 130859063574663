@import "_base.less";
@import "_colors.less";
@import "_buttons.less";
@import "_fonts.less";
@import "_blocks.less";
@import "_animations.less";

.sitewidth() {
    width: 60rem;
    margin: auto;
    max-width: 100%;
    padding: 0 2.5rem;
}

/* Main Content wrapper */
#main-content {
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    text-align: center;

    input.input-field {
        height: 3.33em;
        border: none;
        font-family: Roboto;
        font-weight: 400;
        line-height: 1.28em;
        padding: 1.2143em;
        outline-width: 0;
        border: 1px solid @light-grey !important;
    }

    .button.search {
        width: 20%;
        padding: 1.11em 0 !important;
        background: #00ACD7;
        font-family: Roboto;
        position: relative;
        height: 3.33em;
        font-size: 1em;
        line-height: 1em;
        font-style: normal;
        font-weight: 500;
        color: #FFF;
        text-transform: uppercase;
        padding: 1.11em 1.22em;
        cursor: pointer;
        border: none;
    }

    .main-content-inner {
        display: inline;
    }
}

.main-content-area {
    margin: auto;
}

/* Header */
header {
    height: 30rem;
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: left;

    &.header-image-filter:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url("https://standalone.imagevault.se/publishedmedia/d1gsygk1abc7re0rhzq8/filter.png");
        background-size: cover;
        background-repeat: no-repeat;
    }

    .header-content-wrapper {
        height: 7.5rem;
        position: relative;
        z-index: 400;

        .header-content {
            line-height: 7.5rem;
            transition: all 0.2s;

            .logo {
                margin-left: 2.3rem;
                width: 12em;
            }
            /* Main (top) navigation */
            .main-navigation {
                margin-right: 2.3rem;
                float: right;
                font-family: Klavika;
                text-transform: uppercase;
                /* Visible link items */
                .navlist-visible {
                    display: inline-block;
                    list-style: none;
                    float: left;

                    li {
                        display: inline-block;
                        margin-right: 1.1em;

                        a {
                            text-decoration: none;
                        }

                        &.active a {
                            color: @light-blue;
                        }
                    }

                    li:last-child {
                        margin-right: 0;
                    }
                }
                /* 
                Hamburger menu
            */
                .hamburger-menu {
                    display: inline;
                    margin-left: 1.5em;

                    .icon-hamburger {
                        position: relative;
                        top: -1px;
                        cursor: pointer;
                    }
                    /* Hamburger link items */
                    .navlist-hamburger {
                        .standard-transition();
                        right: -24rem;
                        vertical-align: top;
                        list-style: none;
                        position: fixed;
                        width: 24rem;
                        min-height: 100vh;
                        max-width: 100%;
                        top: 0;
                        z-index: 1000;
                        background-color: @light-blue;
                        color: #fff;
                        padding: 2em 2em;
                        line-height: 2.5em;
                        box-sizing: border-box;
                        font-size: 1rem;
                        overflow: auto;
                        max-height: 100%;

                        &.active {
                            right: 0;
                        }

                        a {
                            text-decoration: none;
                            color: #fff;
                        }

                        ul {
                            width: 100%;

                            li {
                                width: 100%;

                                a {
                                    font-size: 1.3em;
                                    font-weight: 300;
                                    text-decoration: none;
                                }

                                ul {
                                    padding-left: 2em;
                                    font-size: 0.8em;
                                    display: none;
                                }

                                &.expanded ul {
                                    display: block;
                                }
                            }
                        }

                        .close-menu {
                            position: absolute;
                            top: 0;
                            right: 0.5em;
                        }

                        .icon-language {
                            font-size: 1.25em;
                            margin-right: 0.25em;
                        }

                        .language-selector {
                            padding: 1em 0;
                            border-top: 1px solid #fff;
                            border-bottom: 1px solid #fff;
                            margin: 1em 0;
                        }

                        .social-media {
                            margin-top: 1em;

                            a {
                                margin-right: 1em;
                            }

                            .social-media-label {
                                display: block;
                                margin-bottom: 0.5em;
                            }
                        }

                        .expand {
                            display: block;
                            content: "";
                            border: solid #fff;
                            border-width: 0 2px 2px 0;
                            display: inline-block;
                            padding: 5px;
                            transform: rotate(45deg);
                            -webkit-transform: rotate(45deg);
                            top: 0.6em;
                            position: relative;
                            float: right;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    /* Advanced header (like start page) for different headers */
    &.tall-header {
        .main-navigation {
            color: #FFF;

            a {
                color: #FFF;
            }
        }
    }

    &.medium-header {
        background-position-y: 6em;

        &:after {
            background-position-y: 6em;
        }

        background-repeat: no-repeat;

        .main-navigation {
            color: #000;

            a {
                color: #000;
            }
        }

        span.animated-header-text-wrapper {
            position: absolute;
            top: 7.5rem;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            span .animated-header-text {
                display: inline-block;
            }
        }
    }

    &.thin-header {
        .main-navigation {
            color: #000;

            a {
                color: #000;
            }
        }
    }

    &.no-header-image {
        border-bottom: 1px solid @light-grey;
        height: auto;
    }

    span.animated-header-text-wrapper {
        margin-left: 2.7rem;
        max-width: 10em;
        color: white;
        font-family: Klavika, sans-serif;
        font-size: 2.8em;
        min-height: 3em;
        text-align: left;
        display: block;
        z-index: 1;
        position: relative;

        span.animated-header-text::after {
            content: "|";
            -webkit-animation: 1s blink step-end infinite;
            -moz-animation: 1s blink step-end infinite;
            -ms-animation: 1s blink step-end infinite;
            -o-animation: 1s blink step-end infinite;
            animation: 1s blink step-end infinite;
        }
    }

    .ingress-text {
        margin-left: 2.7rem;
        color: #fff;
        display: inline-block;
        clear: both;
        position: relative;
        z-index: 1;

        a {
            border: 1px solid #fff;
            padding: 1em 1.2em;
            color: #fff;
            display: inline-block;
            margin-top: 1em;
            text-decoration: none;
            .button-hover-effect();
            .standard-transition();

            &::after {
                margin-top: 2px;
            }
        }

        p {
            margin-top: 0.4em;
        }
    }
}

/*
    Breadcrumbs
*/
.ul-breadcrumbs {
    text-align: left;
    margin-left: 2.3rem;
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 0.9em;

    li {
        display: inline-block;
        font-family: Roboto, sans-serif;
        font-size: 1.125em;

        a {
            color: #000;
        }

        &:after {
            content: "/";
            font-family: Roboto, sans-serif;
            color: #000;
            font-size: 1.125em;
            padding-left: 0.2em;
            padding-right: 0.2em;
        }

        &:last-child:after {
            content: "";
        }
    }
}

/*
    When scrolled
*/
body.scroll header .header-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid @light-grey;
    z-index: 200;
    line-height: 4.5em;

    a,
    .main-navigation {
        color: #000;
    }
    /*Hide white logo*/
    .white-logo-wrapper {
        display: none !important;
    }
    /* Show black logo*/
    .black-logo-wrapper {
        display: inline-block !important;
    }
}
/*
    Standard content with standard width
*/
.standard-content {
    .sitewidth();
    text-align: left;
    padding-bottom: 2.5rem;
    margin-top: 4rem;

    a {
        color: @light-blue;
    }

    img {
        max-width: 100%;
        height: auto !important;
    }

    .customer-author {
        img {
            float: left;
        }

        span {
            line-height: 58px;
            display: inline-block;
            margin-left: 1em;
            font-size: 0.9em;
        }
    }

    ul {
        list-style: disc;
        padding-left: 40px;
        margin-bottom: 1em;
    }
}
/*
    Content with text
*/
.main-content-text {
    width: 80rem;
    max-width: 100%;
    margin: auto;
    text-align: left;
    padding: 0 2.5rem 2.5rem 2.5rem;

    a {
        color: @light-blue;
        text-decoration: none;
    }
}
/*
    Articles
*/
.article {
    p a {
        color: #000;
    }
}
/*
    Contact info
*/
.contact-info {
    background-color: @light-blue;
    color: #fff;
    padding: 0.5em 0.8em;
    position: fixed;
    top: 60%;
    right: 0;
    text-align: left;
    font-size: 0.8em;
    width: 2.7rem;
    overflow: visible;
    white-space: nowrap;
    z-index: 10;
    .standard-transition();

    .contact-info-click-area {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        left: 0;
        top: 0;
    }

    &.open {
        width: 13em;

        .contact-info-click-area {
            display: none;
        }
    }

    span {
        width: 1.2em;
        margin-right: 0.5em;
        display: inline-block;
        text-align: center;
    }

    .icon-language {
        font-size: 1.15em;
        position: relative;
        left: -0.05em;
        margin-right: 0.3em;
    }

    a {
        color: #fff;
        line-height: 2em;
    }
}
/*
    Scroll to top button
*/
.scroll-top-button {
    background-color: @light-blue;
    padding: 0.5em 0.8em;
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: left;
    font-size: 0.8em;
    overflow: visible;
    white-space: nowrap;
    z-index: 200;
    width: 2.7em;
    text-align: center;

    .up-arrow:before {
        display: block;
        content: "";
        border: solid #fff;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 4px;
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        top: 0.2em;
        position: relative;
    }
}
/*
    Blog posts
*/
section.blog,
section.blog-details {
    width: 80rem;
    max-width: 100%;
    margin: auto;
    padding: 2.5rem;
    text-align: left;

    .blog-post {
        margin-bottom: 2.5rem;
        padding-bottom: 2.5rem;
        border-bottom: 1px solid #ddd;
    }

    h2 {
        font-size: 2.5em;
        font-family: Klavika;
        line-height: 1.2em;
        margin-bottom: 0;
        color: #000;

        a {
            color: #000;
            text-decoration: none;
        }
    }

    h3 {
        font-size: 1.4em;
        font-family: Klavika;
        margin: 0;
        margin-bottom: 0.2em;
    }

    img {
        margin-bottom: 1em;
        max-width: 100%;
    }

    a {
        color: @light-blue;
    }

    p {
        margin-top: 0;

        &.tags {
            margin: 0;
            font-size: 0.9em;
        }
    }
    /*i
    {
        font-size: 0.9em;
    }*/
    .search {
        input.input-field {
            width: 80%;
            float: left;
        }

        .button {
            margin-bottom: 1em;
        }
    }

    .tags-wrapper {
        color: #A9B0B7;

        a {
            color: #A9B0B7;
            text-decoration: none;

            &.active {
                color: @light-blue;
            }
        }
    }

    .facebook,
    .twitter {
        float: right;
        margin-left: 1.5em;

        .icon {
            border-radius: 50%;
            background-color: @light-blue;
            color: #fff;
            font-size: 0.6em;
            line-height: 2.5em;
            text-align: center;
            width: 2.5em;
            height: 2.5em;
            display: inline-block;
            position: relative;
            top: -0.2em;
            margin-left: 0.5em;
        }
    }

    #disqus_thread {
        margin-top: 2em;
    }
}
/*
    Reference page
*/
.reference-image img {
    width: 100%;
    margin: 2em 0;
}
/*
    Reference list
*/
.reference-list {
    margin-top: 0.5em;

    .reference-list-item {
        position: relative;
        width: 25%;
        float: left;
        outline: none;
        overflow: hidden;
        padding-bottom: 3em !important;

        img {
            width: 100%;
        }

        a {
            color: #fff;
        }

        .reference-overlay {
            background-color: rgba(0, 172, 215, 1);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 300;
            width: 100%;
            height: 3em;
            transition: all 0.5s;
        }


        .reference-text {
            color: #fff;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 301;
            padding: 1em 2.5em;
            width: 100%;
            height: 3.35em;
            overflow: hidden;
            transition: all 0.5s;
            font-weight: 300;


            .reference-text-content-wrapper {
                display: table;
                height: 100%;
                width: 100%;

                .reference-text-content {
                    text-align: center;
                    width: 100%;
                    height: 100%;
                    display: table-cell;
                    vertical-align: middle;
                    width: 100%;

                    h3 {
                        display: block;
                        width: 100%;
                        padding-bottom: 0.6em;
                        margin-bottom: 0.6em;
                        border-bottom: 1px solid #fff;
                    }

                    .icon-arrow-right {
                        opacity: 0;
                        transition: all 0.5s;
                        display: block;
                        position: absolute;
                        bottom: -2em;
                        right: 50%;
                        right: ~"calc(50% - 0.4em)";
                        font-size: 1.1em;
                    }
                }
            }
        }

        .reference-image-dark-overlay {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(0,0,0,0.1);
            height: 100%;
        }

        &:hover {
            .reference-text {
                height: 100%;

                .reference-text-content-wrapper {
                    .reference-text-content .icon-arrow-right {
                        opacity: 1;
                        bottom: 1em;
                    }
                }
            }

            .reference-overlay {
                height: 100%;
                background-color: rgba(0, 172, 215, 1);
                bottom: 0;
            }
        }
    }
}


.reference-list.without-slideshow {
    width: 80rem;
    margin: auto;
    max-width: 100%;
    padding: 0.4rem 2.5rem;
    overflow: hidden;

    .reference-list-item {
        width: 32.3333%;
        margin: 0.5%;
    }
}

.reference-list.with-slideshow {
    margin: 0 -0.25em;
    margin-top: 0.5em;

    .reference-list-item {
        width: 25%;
        padding: 0 0.25em;
    }

    .reference-overlay {
        left: 0.25em;
        right: 0.25em;
        width: auto;
    }

    .slick-slider {
        margin-bottom: 0;
    }

    .slick-prev {
        left: 1.7rem !important;
        display: inline-block;
        margin-top: -0.7rem;
        z-index: 15;
        transform: rotate(180deg);
        display: none !important;

        &:before {
            font-size: 1.2rem;
            content: "\f101";
            font-family: IvSymbols;
            padding: 1rem 0.7em;
            opacity: 1;
            background-color: #fff;
            color: @light-blue;
        }
    }


    .slick-next {
        right: 1.7rem !important;
        display: inline-block;
        z-index: 15;
        display: none !important;

        &:before {
            font-size: 1.2rem;
            content: "\f101";
            font-family: IvSymbols;
            padding: 1rem 0.7em;
            opacity: 1;
            background-color: #fff;
            color: @light-blue;
        }
    }
}
/*
    Employee list
*/
.employee-list {
    margin: 1em 0;
    overflow: auto;
    .sitewidth();
    padding: 2.5rem;

    .employee-list-item {
        position: relative;
        width: 31.3333%;
        margin: 1%;
        float: left;
        outline: none;

        img {
            width: 100%;
        }

        a {
            color: #fff;
            word-wrap: break-word;
        }

        .employee-text {
            color: #fff;
            opacity: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: flex-end;
            z-index: 1;
            padding: 1em;
            padding-bottom: 2em;
            width: 100%;
            box-sizing: border-box;
            .standard-transition();

            .employee-text-content {
                text-align: center;
                width: 100%;
                font-size: 0.9em;
                .standard-transition();

                h3 {
                    display: block;
                    width: 100%;
                    margin-bottom: 0;
                }

                p {
                    margin: 0;
                }

                .title {
                    margin: 0;
                    border-bottom: 1px solid #fff;
                    padding-bottom: 0.5em;
                    margin-bottom: 0.5em;
                }
            }
        }

        .employee-overlay {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: #00ADD7;
            background: linear-gradient(325deg, #00ADD7 0, #2B529D 100%);
            opacity: 0;
            width: 100%;
            height: 100%;
            .standard-transition();
        }

        &:hover {
            .employee-overlay {
                opacity: 0.8;
            }

            .employee-text {
                opacity: 1;
            }
        }
    }
}
/*
    Accept newsletter terms
*/
.newsletter-field {
    display: inline-block;
    text-align: left;
    position: relative;
    width: 33rem !important;
    /*
        LEADSIUS
    */
    .ls-form-final {
        padding: 0;
        border: none;
        background: transparent;
        max-width: 100%;
        margin: auto;



        .checkbox {
            width: 100%;
            font-size: 0.8em;
            margin: 0;

            input {
                margin-right: 0.5em;
            }
        }

        .ls-form-group {
            clear: both;
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
        }

        button span, button i, .ls-badge {
            pointer-events: none;
        }

        form {
            position: relative;
        }

        .alert-error {
            color: #c60d0d;
            font-size: 0.8em;
            position: absolute;

            p {
                margin: 0;
            }
        }

        a {
            color: @light-blue !important;
        }
    }
}

/*
    Accept terms
*/
.show-accept-terms {
    background-color: #fff;
    border: 1px solid #000;
    color: #000 !important;
    display: inline-block;
    border-radius: 50%;
    width: 1.3em;
    height: 1.3em;
    text-align: center;
    line-height: 1.3em;
    margin-left: 0.5em;
}

.accept-terms-dialog-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 300;

    .accept-terms-dialog {
        width: 600px;
        max-width: 100%;
        max-height: 100%;
        overflow: auto;
        background-color: #fff;
        padding: 2rem;
        color: #000;
        box-shadow: 0 0 15px rgba(0,0,0,0.2);
        position: relative;

        .close-accept-terms {
            color: #000;
            position: absolute;
            top: 1em;
            right: 1em;
            display: inline-block;
        }
    }
}
// High-dpi mixin
.high-dpi-image(@file-1x, @file-2x, @width, @height) {
    background-image: url(@file-1x);
    background-repeat: no-repeat;

    @media only screen and (-webkit-min-device-pixel-ratio: 1.5), (-moz-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5) {
        background-image: url(@file-2x);
        background-size: @width, @height;
    }
}
/*
    Page specific settings
*/
body.grey {
    background: @light-grey;
}

@media (max-width: 1280px) and (min-width: 768px) {
    body {
        font-size: 1.2em;
    }

    .reference-list {
        font-size: 0.8em;
    }

    .main-footer .footer-columns-inner {
        width: 50%;
        display: inline-block;

        .footerlinks,
        .footer-contact,
        .footer-about {
            margin: 1.5em 0.5em !important;
            width: 80%;
            max-width: initial;
        }
    }
}

@media (max-width: 986px) {
    .bluepuff {
        width: 100% !important;
        margin: 0 0 1em 0 !important;
        max-width: initial !important;
    }
}

@media (max-width: 767px) {
    h1,
    h2 {
        font-size: 1.8em;
    }

    header {
        background-position: center 4rem !important;

        &:after {
            background-position-y: 4rem !important;
        }

        .header-content {
            background: #fff;
        }

        span.animated-header-text-wrapper {
            font-size: 2.2em;
            margin-top: 0.6em;
            /*top: 4rem !important;*/
        }

        &.medium-header {
            span.animated-header-text-wrapper {
                position: absolute;
                top: 7.5rem;
                margin-top: 0 !important;
            }
        }

        .ingress-text {
        }
    }

    .icon-hamburger {
        color: #000;
    }

    .buy-block {
        width: 100% !important;
        margin: 0 0 1em 0 !important;
        max-width: initial !important;
    }
    /*
        References
    */
    .reference-list.without-slideshow {
        .reference-list-item {
            width: 49%;
            margin: 0.5%;

            .reference-text {
                font-size: 0.7em;
            }
        }
    }
    /*
        Employees
    */
    .employee-list {
        .employee-list-item {
            width: 49%;
            margin: 0.5%;
        }
    }

    .main-footer footer {
        padding-bottom: 1em;

        .newsletter-field {
            width: 22rem !important;

            input.newsletter-input {
                width: 14em !important;
            }
        }

        .footer-columns {
            padding: 1em;
            margin-top: 2em;
            margin-bottom: 1em;

            .footer-columns-inner {
                width: 100%;
                text-align: center !important;
                display: inline-block !important;
                text-align: center !important;
                padding-bottom: 3em;

                .footerlinks,
                .footer-about,
                .footer-contact {
                    text-align: center !important;
                    margin: 0 !important;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    body {
        font-size: 100%;
    }

    header {
        font-size: 0.9em;
        height: 26em;



        .header-content-wrapper {
            height: 4rem;

            .header-content {
                line-height: 4rem;
                height: 4rem;

                .main-navigation {
                    margin-right: 1em;

                    .hamburger-menu .navlist-hamburger {
                        font-size: 1rem;
                    }
                }

                .logo {
                    margin-left: 1em;
                }
            }
        }

        span.animated-header-text-wrapper {
            margin-left: 1rem !important;
            top: 1rem;
        }

        .ingress-text {
            margin-left: 1rem;
            margin-top: 2rem;
        }
    }

    .ul-breadcrumbs {
        margin-left: 1rem !important;
    }

    h1,
    h2 {
        font-size: 1.6em !important;
        font-size: 1.6em !important;
    }


    .pluginblock ul {
        display: block;

        li {
            display: block;
            text-align: center;
            width: 100%;
        }
    }

    .main-content-area {
        padding-top: 3rem;
    }

    .main-content-text,
    section.blog,
    section.blog-details,
    .text-block-content,
    .html-text-block-content {
        padding: 1rem !important;
    }

    .contact-info {
        width: 2.2rem;
    }

    .text-block.wide .text-block-content p {
        margin-bottom: 0;
    }

    .standard-content {
        padding: 0 1rem;
    }
    /*
        References
    */
    .reference-list {
        padding: 1em;

        .reference-list-item {
            width: 100%;
            margin: 0;
            margin-bottom: 1em;

            .reference-text {
                font-size: 1em;
            }
        }
    }
    /*
        Employees
    */
    .employee-list {
        padding: 1em;

        .employee-list-item {
            width: 100%;
            margin: 0%;
            margin-bottom: 1em;
        }
    }

    .main-footer footer .newsletter-field {
        width: 20rem !important;

        input.newsletter-input {
            width: 13em !important;
            padding: 1.2143em 0.4em !important;
        }
    }
}
