@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');

@font-face {
    font-family: Klavika;
    src: url('/Fonts/Klavika Regular.otf') format('opentype');
    src: url('/Fonts/Klavika Regular.eot') format('embedded-opentype');
    src: url('/Fonts/Klavika Regular.woff') format('woff');
}

@font-face {
    font-family: Klavika;
    font-weight: bold;
    src: url('/Fonts/Klavika Bold.otf') format('opentype');
    src: url('/Fonts/Klavika Bold.eot') format('embedded-opentype');
    src: url('/Fonts/Klavika Bold.woff') format('woff');
}

@font-face {
    font-family: Klavika-medium;
    src: url('/Fonts/Klavika Medium.otf') format('opentype');
    src: url('/Fonts/Klavika Medium.eot') format('embedded-opentype');
    src: url('/Fonts/Klavika Medium.woff') format('woff');
}
