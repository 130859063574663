@import (reference) "_colors.less";

.standard-transition()
{
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.button-hover-effect()
{
    position: relative;

    &::before
    {
        .standard-transition();
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
        background-color: rgba(255,255,255,0.2);
        -webkit-transform: scale(0.5, 1);
        transform: scale(0.5, 1);
    }

    &.transparent-blue::before
    {
        background-color: rgba(0, 172, 215, 0.05);
    }
    /*transition: all 0.3s;*/
    /*position: relative;*/
    /*span
    {
        display: inline-block;
        .standard-transition();
    }*/
    /*span,
    &::before
    {
        display: inline-block;
        .standard-transition();
    }

    &::after
    {
        .standard-transition();
        opacity: 0;
        display: inline-block !important;
        position: absolute !important;
        margin-left: 2px;
        line-height: inherit;
        content: "\f101";
        font-family: IvSymbols !important;
        font-size: 0.85em;

    }

    &.icon::after
    {
        margin-top: 3px;
    }*/
    &:hover
    {
        &::before
        {
            opacity: 1;
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        /*opacity: 0.8;
        box-shadow: 0 0 5px rgba(0,0,0,0.2);*/
        /*span, &::before
        {
            position: relative;
            transition: all 0.3s;
            transform: translateX(-10px);
        }

        &::after
        {
            opacity: 1;
            right: initial;
            margin-left: -5px;
        }*/
    }
}
/* Button base */
.button,
input[type="submit"]
{
    font-family: Roboto;
    position: relative;
    height: 3.33em;
    font-size: 1em;
    line-height: 1em;
    font-style: normal;
    font-weight: 500;
    color: #FFF;
    text-transform: uppercase;
    padding: 1.11em 1.22em;
    cursor: pointer;
    border: none;
    .standard-transition();
    .button-hover-effect();

    &:hover
    {
        color: #fff;
    }
    /* Blue buttons */
    &.blue
    {
        background: @light-blue;
    }
    /* Transparent button with white borders and text*/
    &.transparent-white
    {
        background: transparent;
        border: 1px solid #FFF;
    }
    /* Transparent button with blue borders and text*/
    &.transparent-blue
    {
        background: transparent;
        color: @light-blue;
        border: 1px solid @light-blue;
    }
}
/* Icons in buttons */
.button.icon::before
{
    font-size: 1.39em;
    line-height: 1em;
    margin-right: 0.3125em;
}
