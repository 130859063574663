/* Global */
/* Reset box-sizing */
html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 100%;
    margin: 0;
}

*, *:before, *:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

/* Body defaults */
body {
    font-family: 'Roboto', sans-serif;
    background: #fff;
    color: #000;
    margin: 0;
    font-size: 1.25em;
    min-width: 320px;
    overflow-x:hidden;

    /*
        Remove leadsius image
    */
    > img{
        display: none;
    }
}

ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

/* Link defaults */
a, a:visited, a:active, a:focus, a:hover
{
    text-decoration: none;
    cursor: pointer;
    color: #FFF;

    outline: none;
}

a:hover{
    text-decoration: none;
}

/* Lists defaults */
ul {
    margin-top: 0;
}

fieldset
{
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
}

h1
{
    font-family: Klavika, sans-serif;
    font-weight: 300;
    font-size: 2.2em;
}

h2
{
    font-weight: 400;
    margin: 0;
    font-size: 1.8em;
    font-family: Klavika, sans-serif;
}

h3
{
    font-weight: 400;
    font-size: 1.3em;
    margin: 0;
    margin-bottom: 0.5em;
    font-family: Klavika, sans-serif;
}

h4
{
    font-size: 1.3em;
    font-family: Klavika, sans-serif;
}

video{
    max-width: 100%;
    height: 100%;
}

/*
    MISC
*/
.clear{
    clear: both;
}

a .icon
{
    display: inline-block;

    &:before
    {
        padding-right: 5px;
    }

    &:empty:before
    {
        padding-right: 0;
    }
}