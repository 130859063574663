@import (reference) "_colors.less";
@import (reference) "main.less";

.whiteblockiconbutton
{
    text-align: center;
    margin: auto;
    width: 53.125em;
    padding: 0 2em;
    max-width: 100%;
    margin-bottom: 4.25em;

    &.wide
    {
        h1
        {
            margin-top: 1.4em;
            font-weight: 400;
            line-height: 1.263em;
            padding-bottom: 1em;
        }
    }
}

.bluepuff
{
    position: relative;
    background-color: #00ADD7;
    background: linear-gradient(325deg, #00ADD7 0, #2B529D 100%);
    color: #FFF;

    &.narrow
    {
        vertical-align: middle;
        display: inline-block;
        width: 31%;
        max-width: 22em;
        height: 23.75em;
        padding: 0 1.5625em 2.125em 1.5625em;
        text-align: center;
        margin-left: 1%;
        margin-right: 1%;
        margin-bottom: 1em;

        h3
        {
            margin-top: 1em;
            line-height: 1.38em;
        }


        p
        {
            font-family: Roboto;
            line-height: 1.17em;
            left: 1.39em;
            right: 1.39em;
        }

        div.header
        {
            position: relative;
            margin-top: 4em;
            margin-bottom: 0.3125em;
            display: block;
            vertical-align: middle;
        }

        div.icon:before
        {
            font-size: 2.5em;
        }

        div.footer
        {
            display: block;

            .link
            {
                position: absolute;
                bottom: 3.125em;
            }
        }
    }

    &.wide
    {
        display: block;
        width: 100%;
    }
}

.buy-block
{
    padding-top: 2.5rem;

    &.narrow
    {
        vertical-align: top;
        display: inline-block;
        width: 32%;
        max-width: 22em;
        text-align: center;
        margin-left: 0.5%;
        margin-right: 0.5%;
        margin-bottom: 1em;

        .buy-content
        {
            background-color: #fff;

            .header
            {
                background-color: #00ADD7;
                background: linear-gradient(325deg, #00ADD7 0, #2B529D 100%);
                color: #fff;
                font-size: 1.5em;
                line-height: 3em;
                text-align: center;
                font-family: Klavika;

                &.gray
                {
                    background: #3C4652;
                }

                &.blue
                {
                    background: #2C5A9D;
                }

                &.pink
                {
                    background: #F01684;
                }
            }

            .buy-list
            {
                padding: 1em;
                text-align: left;

                ul
                {
                    li
                    {
                        line-height: 2em;
                        padding-left: 1.5em;
                        position: relative;

                        &:before
                        {
                            content: "";
                            display: inline-block;
                            width: 0.5em;
                            height: 0.5em;
                            border-radius: 50%;
                            background-color: @light-blue;
                            border: none;
                            text-align: center;
                            position: absolute;
                            left: 0;
                            margin-top: 0.7em;
                        }
                    }

                    li:nth-child(n+6)
                    {
                        display: none;
                    }
                }

                &.show-all ul li
                {
                    display: list-item;
                }

                .show-features,
                .hide-features
                {
                    color: @light-blue;
                    margin-bottom: 1em;
                    margin-top: 2em;
                    display: block;
                    text-align: center;

                    &::after
                    {
                        display: block;
                        content: "";
                        border: solid @light-blue;
                        border-width: 0 2px 2px 0;
                        display: inline-block;
                        padding: 5px;
                        position: relative;
                        margin-left: 0.5em;
                        top: 0.1em;
                    }
                }

                .hide-features
                {
                    &::after
                    {
                        transform: rotate(-135deg);
                        -webkit-transform: rotate(-135deg);
                    }
                }

                .show-features
                {
                    &::after
                    {
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                        top: -0.2em;
                    }
                }

                .hide
                {
                    display: none;
                }
            }

            hr
            {
                margin: 0 2em;
            }

            .price
            {
                font-size: 1.5em;
                text-align: center;
                padding: 1em 0;
                display: block;
            }



            .buy-link
            {
                display: inline-block;
                margin-bottom: 2em;
            }
        }
    }

    &.wide
    {
        display: block;
        width: 100%;
    }
}

.blogblock
{
    position: relative;
    background: @light-grey;
    /* TODO: REMOVE THIS DEBUG STUFF */
    height: 1130px;
    text-align: center;

    h2
    {
        color: #000;
        padding-top: 200px;
    }
    /* DEBUG END */
    &.wide
    {
        display: block;
        width: 100%;

        .blog-footer
        {
            width: 100%;
            position: absolute;
            bottom: 2.625em;
            text-align: center;
        }
    }
}

.pluginblock
{
    text-align: center;
    padding-bottom: 4em;
    padding-top: 2.3875em;
    clear: both;

    h2
    {
        margin-top: 1em;
        margin-bottom: 1.30625em;
        line-height: 1.1875em;
        font-weight: 400;
    }

    .slick-list{
        overflow: hidden;
    }

    ul
    {
        list-style: none;
        padding-left: 0;
        li
        {
            padding-top: 1em;
            height: 100%;
            span.pluginimage-helper
            {
                height: 100%;
                display: inline-block;
                vertical-align: middle;
            }

            img
            {
                display: inline-block;
                height: 4.5em;
                vertical-align: middle;
                -webkit-transition: all .1s ease-in-out;
                transition: all .1s ease-in-out;
            }

            img:hover
            {
                transform: scale(1.1);
            }

            a.pluginimage-text
            {
                font-family: Roboto;
                line-height: 1.28em;
                font-weight: 400;
                display: block;
                color: #000;
                padding-top: 1em;
            }
        }

        li:last-child
        {
            margin-right: 0;
        }
    }

    .button
    {
        display: inline-block;
        margin-top: 2.8125em;
    }
}

.generalblueblock
{
    &.wide
    {
        text-align: center;
        width: 100%;
        background-color: #00ADD7;
        background: linear-gradient(325deg, #00ADD7 0, #2B529D 100%);
        color: #FFF;
        text-align: center;
        padding: 3em 2em;
        overflow: hidden;

        .blueblock-content
        {
            h2
            {
                font-weight: 400;
                max-width: 27.5em;
                margin: auto;
            }

            .button
            {
                display: inline-block;
                margin-top: 2.5rem;
            }
        }
    }
    /*
        Contact form and demo form
    */
    .ls-form-final
    {
        width: 50rem !important;
        margin: auto;
        max-width: 100%;
        padding: 0 2rem;
        display: block;

        .ls-form-group
        {
            text-align: center;
            margin-bottom: 1.5em;

            > label
            {
                display: block;
                clear: both;
                font-size: 1.4em;
                margin: 0;
                text-align: left;
            }
        }

        input[type=text], textarea
        {
            width: 100%;
            min-height: 3.33em;
            border: none;
            font-family: Roboto;
            font-weight: 400;
            line-height: 1.28em;
            padding: 1.2143em;
            outline-width: 0;
            border: 1px solid #EAEBED !important;
        }

        .checkbox
        {
            text-align: left;
            display: block;
            line-height: 2em;
            margin-right: 1em;

            input
            {
                margin-right: 0.5em;
            }
        }

        .parent-checkbox
        {
            label
            {
                margin-left: 1em;
                display: inline-block;
                input{
                    margin-left: -1.1em;
                }
            }
        }


        .fb-required
        {
            display: none !important;
        }


        button
        {
            background: transparent !important;
            border: 1px solid #fff;
            font-family: Roboto;
            position: relative;
            height: 3.33em;
            line-height: 1em;
            font-style: normal;
            font-weight: 500;
            color: #FFF;
            text-transform: uppercase;
            padding: 1.11em 1.22em;
            cursor: pointer;
            float: right;
        }

        .content-popup p
        {
            margin: 0;
        }

        .ls-popup
        {
            text-align: left;
        }
    }
}

.whiteblockmultibutton
{
    &.wide
    {
        text-align: center;
        display: table;
        height: 21.4375em;
        width: 100%;
        color: #000;
        background: #FFF;
        text-align: center;

        .whiteblock-content
        {
            display: table-cell;
            vertical-align: middle;
            margin: auto;
            padding: 0 1em;

            h2
            {
                line-height: 1.1875em;
                font-weight: 400;
                margin-top: 0;
            }

            p
            {
                max-width: 53.125em;
                margin: auto;
            }

            .button
            {
                display: inline-block;
                margin-top: 2.5rem;
                margin-right: 1.375em;
                vertical-align: middle;

                &:last-child
                {
                    margin-right: 0;
                }
            }
        }
    }
}

.main-footer
{
    border-top: 1px solid #ddd;

    footer
    {
        background-color: @light-grey;
        text-align: center;
        padding-bottom: 3em;
        clear: both;

        h2
        {
            display: block;
            line-height: 1.1875em;
            margin-top: 0;
            padding-top: 1.40625em;
        }

        p
        {
            display: block;
            max-width: 22.22em;
            margin: 0.8em 0;
        }

        .footer-top > p
        {
            margin: auto;
            margin-bottom: 1.1em;
        }

        input.newsletter-input
        {
            width: 22em;
            margin: auto;
            display: inline-block;
            vertical-align: middle;
        }

        input[type="submit"]
        {
            display: inline-block;
            vertical-align: middle;
        }

        .footerlinks, .footer-contact, .footer-about
        {
            vertical-align: top;
            margin-top: 3.125em;
            display: inline-block;
            max-width: 16.25em;
            text-align: left;
            margin-left: 2.5rem;
            margin-right: 2.5rem;

            h4
            {
                color: #000;
                text-transform: uppercase;
                margin-bottom: 0.8em;
                margin-top: 0;
                font-weight: 500;
            }

            a
            {
                font-weight: 600;
                text-decoration: none;
                color: @light-blue;
            }

            ul
            {
                display: block;
                font-weight: 500;
            }
        }

        .footer-contact
        {
            max-width: 10.3125em;

            a
            {
                font-weight: 400;
                color: #000;
                text-decoration: none;
            }

            ul.socialmedia-links
            {
                margin-top: 1.25em;

                li
                {
                    display: inline-block;
                    margin-right: 0.4375em;

                    a.blue-circle
                    {
                        color: #FFF;
                    }

                    a.blue-circle:before
                    {
                        display: table-cell;
                        width: 2.2142em;
                        height: 2.2142em;
                        border-radius: 50%;
                        background-color: @light-blue;
                        border: none;
                        font-size: 0.8em;
                        text-align: center;
                        vertical-align: middle;
                    }
                }

                li:last-child
                {
                    margin-right: 0;
                }
            }
        }
    }
}

.footer-columns
{
    .footer-columns-inner
    {
        display: inline;
    }
}

.grey-html-block, .white-html-block
{
    background: @light-grey;
    display: block;
    padding-top: 3.125em;
    padding-bottom: 3.75em;

    .grey-html-content, .white-html-content
    {
        max-width: 56.25em;
        margin: auto;

        h3
        {
            text-align: left;
            color: @light-blue;
            font-weight: 500;
            font-size: 1.625em;
            line-height: 1.077em;
            margin-bottom: 0.46em;
            margin-top: 0;
        }

        p
        {
            text-align: left;
            color: #000;
            margin-top: 0.05em;
            margin-bottom: 1.67em;

            &.image-centered
            {
                text-align: center;
            }

            &.image-list
            {
                text-align: center;

                img
                {
                    margin-right: 1.27em;

                    &:last-child
                    {
                        margin-right: 0;
                    }
                }
            }
        }

        ul
        {
            text-align: left;

            li
            {
                padding-left: 1.6em;
                text-indent: -1.4em;
                margin-bottom: 0.5em;

                &::before
                {
                    content: "";
                    display: inline-block;
                    border: none;
                    border-radius: 50%;
                    background-color: @light-blue;
                    width: 0.5em;
                    height: 0.5em;
                    margin-right: 0.875em;
                    vertical-align: middle;
                }
            }
        }
    }

    &.narrow
    {
        display: inline-block;
        text-align: center;
        vertical-align: top;
        margin-left: 1.5em;
        margin-right: 1.5em;

        .grey-html-content, .white-html-content
        {
            max-width: 27em;
            text-align: left;
        }
    }
}

.white-html-block
{
    background: #FFF;
}

.button-block
{
    &.wide
    {
        display: block;
        text-align: center;
        margin-top: 0;
        margin-bottom: 5em;
        padding-top: 2.5rem;

        .button
        {
            display: inline-block;
            margin-right: 1.375em;
            vertical-align: middle;

            &:last-child
            {
                margin-right: 0;
            }
        }
    }
}

.text-block
{
    max-width: 100%;

    &.white
    {
        background-color: #fff;
    }

    &.grey
    {
        background-color: @light-grey;
    }

    &.wide
    {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        display: block;

        .text-block-content
        {
            margin: auto;
            text-align: left;
            color: #000;
            padding: 0 2.5rem;
            width: 60rem;
            margin: auto;
            max-width: 100%;

            h2
            {
                font-weight: 100;
                margin-bottom: 0;
                margin-top: 0;
            }

            p
            {
                margin-top: 0;
                white-space: pre-line;
                margin-bottom: 2em;

                &:last-child
                {
                    margin-bottom: 0 !important;
                }
            }

            .main-content-area
            {
                margin-top: -2em;
            }
        }
    }
}

.media-block
{
    &.wide
    {
        margin: auto;
        padding: 2.5rem;
        width: 60rem;
        margin: auto;
        max-width: 100%;

        img
        {
            width: 100%;
        }
    }

    &.narrow
    {
        display: inline-block;
        /*max-width: 23.3625em;*/
        margin: auto;

        img
        {
            width: 100%;
            margin-bottom: 2em;
        }
    }
}

.html-text-block
{
    max-width: 100%;

    h3
    {
        font-weight: 600;
    }

    ul
    {
        text-align: left;

        li
        {
            padding-left: 1.6em;
            text-indent: -1.4em;
            margin-bottom: 0.5em;

            &::before
            {
                content: "";
                display: inline-block;
                border: none;
                border-radius: 50%;
                background-color: @light-blue;
                width: 0.5em;
                height: 0.5em;
                margin-right: 0.875em;
                vertical-align: middle;
            }
        }
    }

    a
    {
        color: @light-blue;
    }

    &.white
    {
        background-color: #fff;
    }

    &.grey
    {
        background-color: @light-grey;
    }

    &.wide
    {
        display: block;

        .html-text-block-content
        {
            margin: auto;
            text-align: left;
            padding: 2.5rem;
            width: 60rem;
            margin: auto;
            max-width: 100%;


            h2
            {
                font-weight: 100;
                margin-bottom: 0;
            }

            h3
            {
                font-weight: 100;
                line-height: 1.25em;
            }

            p
            {
                margin-top: 0;
                white-space: pre-line;
                margin-bottom: 2em;

                &:last-child
                {
                    margin-bottom: 0;
                }
            }

            a
            {
                color: @light-blue;
            }
        }
    }

    &.narrow
    {
        display: inline-block;
        vertical-align: top;

        .html-text-block-content
        {
            margin: auto;
            text-align: left;
            max-width: 27em;
            padding-top: 2.5rem;

            h2
            {
                font-weight: 100;
                margin-bottom: 0;
            }

            h3
            {
                font-weight: 100;
                line-height: 1.25em;
            }

            p
            {
                margin-top: 0;
                white-space: pre-line;
                margin-bottom: 2em;
            }
        }

        &.left
        {
            margin-right: 6em;
        }

        &.right
        {
            margin-right: 0;
        }
    }
}

/*
    Remove padding for nested html text blocks
*/
.html-text-block-content .html-text-block-content,
.text-block-content .text-block-content,
.text-block-content .media-block
{
    padding-left: 0;
    padding-right: 0;
}

// Don't double pad nested HTML-text-blocks
.html-text-block-content-additional
{
    .html-text-block
    {
        padding: 0;
    }
}

// DEMO
img.blogmedia-thumb
{
    border-radius: 8px;
    border: none;
}

.blogmedia-metadata
{
    font-style: italic;
}
