// Animations
@keyframes blink {
    from, to {
        color: transparent;
    }

    50% {
        color: #fff;
    }
}

@-moz-keyframes blink {
    from, to {
        color: transparent;
    }

    50% {
        color: #fff;
    }
}

@-webkit-keyframes blink {
    from, to {
        color: transparent;
    }

    50% {
        color: #fff;
    }
}

@-ms-keyframes blink {
    from, to {
        color: transparent;
    }

    50% {
        color: #fff;
    }
}

@-o-keyframes blink {
    from, to {
        color: transparent;
    }

    50% {
        color: #fff;
    }
}
